import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import { Link } from 'react-router-dom';

export default function About () {
            
    return (
      <div>
        <Navbar/>
        <div className='home--part'>  
        <div className="description--author">
          <div className="description--author--content--left">
            <h1 className="name--author">About Me</h1>
            <p> My name is Doumbia Fode. I'm a freelance Full Stack Developer based in Abidjan , CIV, and i'm very passionate and dedicated to my work.</p>
            <p>I also make the website more & more interactive with web animations. <br/> A responsive design makes your website accessible to all users, regardless of their device.</p>
            <h1 className="name--author"> My skills</h1>
            <div className='container__skills'>
              <div className='skills__content'>
                  <div className='skills__box'>
                    <Link><img src="https://ih1.redbubble.net/image.438908244.6144/bg,f8f8f8-flat,750x,075,f-pad,750x1000,f8f8f8.u2.jpg" alt="" /></Link>
                    <a href="https://expressjs.com/" className='link__skills'> <p>Express Js</p></a>
                  </div>
                  <div className='skills__box'>
                    <Link><img src="https://cdn-clekk.nitrocdn.com/tkvYXMZryjYrSVhxKeFTeXElceKUYHeV/assets/images/optimized/rev-49e2c5e/litslink.com/wp-content/uploads/2020/12/node.js-logo-image.png" alt="" /></Link>
                    <a href="https://nodejs.org/en" className='link__skills'> <p>Node Js</p></a>
                  </div>
                  <div className='skills__box'>
                    <Link><img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRBmjFGJyjhQ3b9orL3YV4zP48AAEA3kXr99luOZnrmyg&s" alt="" /></Link>
                    <a href="https://fr.legacy.reactjs.org/" className='link__skills'> <p>React Js</p></a>
                  </div>

                  <div className='skills__box'>
                    <Link><img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIvqzv6j2llxzwAJKVIa6Gv0abIQA8Qo6Ud3TyohWvvV6CW5InpU6mnyEKkDCsYAkhgO0&usqp=CAU" alt="" /></Link>
                    <a href="https://render.com/" className='link__skills'> <p>Render</p></a>
                  </div>
                  <div className='skills__box'>
                    <Link><img src="https://miro.medium.com/v2/resize:fit:512/1*doAg1_fMQKWFoub-6gwUiQ.png" alt="" /></Link>
                    <a href="https://www.mongodb.com/fr-fr" className='link__skills'> <p> MongoDB</p></a>
                  </div>
                  <div className='skills__box'>
                    <Link><img src="https://getbootstrap.com/docs/5.3/assets/brand/bootstrap-logo-shadow.png" alt="" /></Link>
                    <a href="https://getbootstrap.com/" className='link__skills'> <p>Bootstrap</p></a>
                  </div>
                  <div className='skills__box'>
                    <Link><img src="https://www.gstatic.com/devrel-devsite/prod/v66c4dc9b65fea2172a0927d7be81b5b5d946ea60fc02578dd7c264b2c2852152/firebase/images/lockup.svg" alt="" /></Link>
                    <a href="https://firebase.google.com/" className='link__skills'> <p> Firebase</p></a>
                  </div>
              </div>
              <button className='btn__cv'>Consulter CV</button>
            </div>
              
          </div>
            <div className="description--author--content--right">
                <div className='images'>
                <img src="2.jpg" alt="" />
                </div>
            </div>
        </div>
          </div>
         <Footer/>
      </div>
    );
}

