import Navbar from './Navbar';
import 'animate.css';
import Footer from '../Pages/Footer';
import '../App.css';
import { useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';


export default function Home () {
  const [nav , setNav] = useState(false);
  const [display,setDisplay]=useState(false);
  const navigate = useNavigate();

    const openLog = ()=>{
      setNav(true)
    }

  const showProfil =()=>{
    setDisplay(true)
  }
  const closeProfil =()=>{
    setDisplay(false)
  }
  const showMenu = () =>{
    setDisplay(true)
}
const affi = ()=> {
  setDisplay (false)
}

    return (
        <div>
             
          <div className='home'>
         <div className='na'>
         <div className='icon--menu' onClick={showMenu}>
            <i className="fa-solid fa-bars"></i>
       </div>
      <div className='na--title'>
      <Link to="/" className='header__title'><h5>MY<span>PORTFOLIO</span></h5></Link>
      </div>
         <div className={display ? "menu show--menu" : "menu"}>
            <div className='sidebar--menu'>
                <div className='btn--close--sidebar' onClick={affi}>
                <Link to="/" className='header__title'><h5>MY<span>PORTFOLIO</span></h5></Link>
                    <i className="fa-solid fa-xmark"></i>
                </div>
                <div className='menu__content'>
                  <div className='menu__content__liste'>
                    <Link className='list' to="/"><i class="fa-solid fa-house"></i> Accueil</Link>
                  </div>
                  <div className='menu__content__liste'>
                  <Link className='list' to='/about'><i class="fa-brands fa-slack"></i> About Me</Link>
                  </div>
                  <div className='menu__content__liste'>
                  <Link className='list' to='/services'><i class="fa-solid fa-bars"></i> Services</Link>
                  </div>
                    <div className='menu__content__liste'>
                    <Link className='list' to='/portfolio'><i class="fa-solid fa-folder"></i> My Portfolio</Link>
                    </div>
                    <div className='menu__content__liste'>
                      <Link className='list' to='/contact'> <i class="fa-solid fa-phone"></i> Contact</Link>
                    </div>
                </div>
                </div>

          </div>
         </div>
          <div className="main--part">
              {/* <Navbar/> */}
              <div className='nav__first'>
              <Link className='list' to="/"><i className="fa-solid fa-house"></i> Accueil</Link>
              <Link className='list' to="/about"><i className="fa-brands fa-slack"></i> About Me</Link>
              <Link className='list' to="/services"><i className="fa-solid fa-bars"></i> Services</Link>
              <Link className='list' to="/portfolio"><i className="fa-solid fa-folder"></i> My Portofolio</Link>
              <Link className='list' to="/contact"><i class="fa-solid fa-phone"></i> Contact</Link>
              </div>
    
                      {/* <img src={`${process.env.PUBLIC_URL}/df.jpg`} alt=""/> */}
                      <div className='description__dev'>
                         <div className='description__dev__left'>
                            <h1 className='name'>Hi, I'm DOUMBIA <span>FODE</span></h1>
                            <p className='dev__off'>Developer Full Stack Js & Informatician</p>
                            <p className='description'>I'm a freelance Developer Full Stack Js based in Abidjan , CIV, and 
                              i'm very passionate <br/> and dedicated to my work.</p>
                          <p className='description'>I also make the website more & more interactive with 
                            web animations. A responsive design makes your website accessible to all users, regardless of their device.</p>
                            <button className='btn__cv'>Consulter CV</button>

                         </div>
                        <div className='description__dev__right'>
                          <div className='dev__right__box'>
                            <i className="fa-brands fa-facebook"></i>
                          </div>
                          <div className='dev__right__box'>
                          <i className="fa-brands fa-instagram"></i>
                          </div>
                          <div className='dev__right__box'>
                          <i className="fa-brands fa-linkedin"></i>
                          </div>
                        </div>
                      </div>
      
                </div>
                <div className="main__part__services">
            <h2 className='services--header'>My Services</h2>  
        <div className="card__content">
          <div className="card">
            <i className="fas fa-bars"></i>
            <h5 className='card--title'>Web Development</h5>
            <div className="card--body">
              <p>Every website should be built with two primary goals: Firstly, it needs to work across all devices. Secondly, it needs to be fast as possible.</p>
              <button className='btn__more'>Read More</button>
            </div>
          </div>

          <div className="card">
            <i className="far fa-user"></i>
            <h5 className='card--title'>Web Development</h5>
            <div className="card--body">
              <p>Every website should be built with two primary goals: Firstly, it needs to work across all devices. Secondly, it needs to be fast as possible.</p>
              <button className='btn__more'>Read More</button>
            </div>
          </div>

          <div className="card">
            <i className="far fa-bell"></i>
            <h5 className='card--title'>Web Development</h5>
            <div className="card--body">
              <p>Every website should be built with two primary goals: Firstly, it needs to work across all devices. Secondly, it needs to be fast as possible.</p>
              <button className='btn__more'>Read More</button>
            </div>
          </div>
        </div>
            
              </div>
              <div className="main--contact">
              <div className='main--contact--header'>
                  <h2 className='contact--me'>Contact Me</h2>
                    <h4>Have you any questions ?</h4>
                        <h5>I'm at your service</h5>
                  </div>
                  <div className='main--contact--content'>
                      <div className='card--contact'>
                        <div className='card--contact--header'>
                        <i class="fa-solid fa-phone"></i>
                        </div>
                          <h4 className='contact--title'>Call Us</h4>
                        <h5>+225 07 77 88 00 82</h5>
                      </div>
                      <div className='card--contact'>
                      <div className='card--contact--header'>
                       <i class="fa-solid fa-location-dot"></i>
                       </div>
                       <h4 className='contact--title'>Office</h4>
                       <h5>Cocody Angré</h5>
                      </div>
                      <div className='card--contact'>
                      <div className='card--contact--header'>
                        <i class="fa-solid fa-envelope"></i>
                        </div>
                        <h4 className='contact--title'>Email</h4>
                        <h5>doumbia77fode@gmail.com</h5>
                      </div>
                      <div className='card--contact'>
                      <div className='card--contact--header'>
                        <i class="fa-solid fa-globe"></i>
                        </div>
                        <h4 className='contact--title'>Website</h4>
                        <h5>monportfolio.mayedo.ci</h5>
                      </div>
                  </div>
            </div>
             
            
        </div>
          
         <Footer/>
      </div>
    )
  }
