import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { coffee } from '@fortawesome/free-brands-svg-icons'
function  Footer () {
            
return (
    <div className='footer'>
    <div className='right'>
      <p>Copyright © 2023,Doumbia <span>Fode</span> </p>
      </div>
    <div className="foot--content">
        <div className='address'>
          <h4>Contactez-nous</h4>
          <p><i className="fa-solid fa-location-dot"></i> Cocody Angré</p>
          <p><i className="fa-solid fa-phone"></i> +225 07 77 88 00 82</p>
          <p><i className="fa-solid fa-envelope"></i> monportfolio.mayedo.ci</p>
          <p><i className="fa-solid fa-globe"></i> www.monportfolio.mayedo.ci</p>
        </div>

        <div className='social--media'>
          <h4>Réseaux sociaux</h4>
            <p><i className="fa-brands fa-facebook"></i>Facebook</p>
            <p><i className="fa-brands fa-instagram"></i>Instagram</p>
            <p><i className="fa-brands fa-whatsapp"></i>WhatsApp</p>
            <p><i class="fa-brands fa-tiktok"></i> Tiktok</p>
        </div>
    </div>
</div> 

    );
}

export default Footer;
