import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import { Link } from 'react-router-dom';

export default function Portfolio () {
            
    return (
     <div>
     <Navbar/>
       <div className='home'>
        <div className="main__part__portfolio">
            <h2 className='services--header'>My Portfolio</h2>
            <h3 className='last__project'>My Projects : </h3>  
                <div className="card__content__block">
                <div class="cards">
                    <a href='https://docteur-piece.mayedo.ci/'> <img src={`${process.env.PUBLIC_URL}/docteur.png`} alt=""/></a>
                </div>
                <div class="cards">
                   <a href="https://delices.mayedo.ci/"> <img src={`${process.env.PUBLIC_URL}/deli.png`} alt=""/></a>
                </div> 
                <div class="cards">
                <a href='https://shoppingbag.mayedo.ci/'> <img src={`${process.env.PUBLIC_URL}/easy.png`} alt=""/></a>
                </div>
                <div class="cards">
                    <a href='https://mayedo.ci/'> <img src={`${process.env.PUBLIC_URL}/mayedo.png`} alt=""/></a>
                </div>
                <div class="cards">
                    <a href='https://shoppingbag.mayedo.ci/'> <img src={`${process.env.PUBLIC_URL}/apfa.png`} alt=""/></a>
                </div>
                <div class="cards">
                    <a href='https://miami.mayedo.ci/'> <img src={`${process.env.PUBLIC_URL}/miami.png`} alt=""/></a>
                </div>
                <div class="cards">
                    <a href='https://devforum.mayedo.ci/'> <img src={`${process.env.PUBLIC_URL}/dev.png`} alt=""/></a>
                </div> 
                <div class="cards">
                    <a href='https://dgpn.mayedo.ci/'> <img src={`${process.env.PUBLIC_URL}/dgpn.png`} alt=""/></a>
                </div> 
                <div class="cards">
                    <Link> <img src={`${process.env.PUBLIC_URL}/Netflix.png`} alt=""/></Link>
                </div>  
                <div class="cards">
                    <a href='https://doumbisss.github.io/lmc-shop--react/'> <img src={`${process.env.PUBLIC_URL}/lmc.png`} alt=""/></a>
                </div>
                <div class="cards">
                    <a href='https://pureprocleaners.ci/'> <img src={`${process.env.PUBLIC_URL}/purepro.png`} alt=""/></a>
                </div>  
               
                <div class="cards">
                    <a href='https://myschool.mayedo.ci/'> <img src={`${process.env.PUBLIC_URL}/school.png`} alt=""/></a>
                </div>
                <div class="cards">
                    <a href='https://legaltech.mayedo.ci/'> <img src={`${process.env.PUBLIC_URL}/legal.png`} alt=""/></a>
                </div>
                <div class="cards">
                    <a href='https://mytodolist.mayedo.ci/'> <img src={`${process.env.PUBLIC_URL}/todolist.png`} alt=""/></a>
                </div>
                <div class="cards">
                    <a href='https://hetea.mayedo.ci/'> <img src={`${process.env.PUBLIC_URL}/hetea.png`} alt=""/></a>
                </div>
                </div>                         
        </div>
         </div>
         <Footer/>
     </div>
    );
}

